import React, { useState, useEffect } from 'react';
import { Column, SpaceBetween, Flex } from '../../../components';
import { Button, RedButton } from '../../../components/base/Buttons';
import { styled } from '@mui/material/styles';

import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import { Text, AlignItems } from '../../../components';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import { CampaignEntityGroup, Stakeholder } from './types';

import EntityForm from './SecondStepModalForms/EntityForm';
import EntityGroupForm from './SecondStepModalForms/EntityGroupForm';

import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import dropdownArrow from '../../../assets/images/icon-drop-down.svg';
import templatesIcon from '../../../assets/images/icon-open-tempates.svg';
import iconGreyPlus from '../../../assets/images/icon-grey-plus-circle.svg';
import Tree from './Tree';
import {v4 as uuid} from 'uuid';
import { Typography } from '@mui/material';
import { PermissionObject } from '../types';

const ENTITIES_GROUPS = gql`
    query entityGroups2($clientId: Int!, $campaignId: String) {
        entityGroups {
            id
            name
            archivedDate
            type {
                id
                name
            }
            client {
                id
                name
            }
            owner {
                id
                firstName
                lastName
                email
            }
            stakeholders {
                id
                firstName
                lastName
                email
            }
            campaignEntities: entities {
                id
                name
                archivedDate
                type {
                    id
                    name
                }
                owner {
                    id
                    firstName
                    lastName
                }
                stakeholders {
                    id
                    firstName
                    lastName
                }
            }
        }
        entityTypes {
            value: id
            label: name
        }
        stakeholdersByClient(clientId: $clientId, campaignId: $campaignId) {
            value: id
            firstName
            lastName
            status
        }
    }
`;

type Props = {
    secondStep: CampaignEntityGroup[];
    clientId: number;
    getSecondStepValues?: any;
    newStakeHolders: string[];
    setNewStakeholders: any;
    allStakeholdersInCampaign: string[];
    setAllStakeholdersInCampaign: any;
    campaignId?: string;
    campaignTitle?: string;
    setMembers: any;
    setReadOnlyMembers: any;
    members: number[];
    readOnlyMembers: number[];
    managerId?: number[];
    permissionStructureData: PermissionObject[];
    setPermissionStructureData: any

};

type User = {
    value: number;
    lastName: string;
    firstName: string;
    status: string;
};

const entityStatusList = [
    {
        id: 'ACTIVE',
        label: 'Active'
    },
    {
        id: 'ARCHIVED',
        label: 'Archived'
    }
];

const SecondStep: React.FC<Props> = ({
    secondStep,
    getSecondStepValues,
    clientId,
    newStakeHolders,
    setNewStakeholders,
    allStakeholdersInCampaign,
    setAllStakeholdersInCampaign,
    campaignId,
    campaignTitle,
    setMembers,
    setReadOnlyMembers,
    members,
    readOnlyMembers,
    managerId,
    permissionStructureData,
    setPermissionStructureData
}) => {
    const [selectedEntityGroups, setSelectedEntityGroups] = useState<CampaignEntityGroup[]>([]);
    const [clonedEntityGroups, setClonedEntityGroups] = useState<CampaignEntityGroup[]>([]);

    const entityStatus: string | unknown = 'ACTIVE';

    const { loading, data } = useQuery(ENTITIES_GROUPS, {
        variables: {
            clientId,
            campaignId
        },
        context: {
            headers: {
                'pearl-client-uuid': clientId
            }
        }
    });

    const [selectedEntity, setSelectedEntity] = useState(false);
    const [entityGroupOpen, setEntityGroupOpen] = useState(false);
    const [entityOpen, setEntityOpen] = useState(false);
    const [selectedEntityGroup, setSelectedEntityGroup] = useState<CampaignEntityGroup | null>(null);

    const searchString = '';

    useEffect(() => {
        !loading &&
            setClonedEntityGroups(
                data &&
                    data.entityGroups.map((el: any) => ({
                        name: el.name,
                        archivedDate: el.archivedDate,
                        campaignEntities: el.campaignEntities.map(
                            (entity: any) => ({
                                name: entity.name,
                                archivedDate: entity.archivedDate,
                                ownerId: entity.owner.id,
                                entityTypeId: entity.type.id,
                                stakeholderIds:
                                    (entity.stakeholdersByClient &&
                                        entity.stakeholdersByClient.map(
                                            (stakeholder: Stakeholder) =>
                                                stakeholder.id
                                        )) ||
                                    []
                            })
                        ),
                        ownerId: el.owner.id,
                        entityTypeId: el.type.id,
                        stakeholderIds:
                            (el.stakeholdersByClient &&
                                el.stakeholdersByClient.map(
                                    (stakeholder: Stakeholder) => stakeholder.id
                                )) ||
                            []
                    }))
            );
    }, [loading, data]);

    useEffect(() => {
        secondStep && setSelectedEntityGroups(secondStep);
    }, []);

    const handleSelectCampaignStatus = () => console.log('asd');

    useEffect(() => {
        getSecondStepValues && getSecondStepValues(selectedEntityGroups);
    }, [getSecondStepValues, selectedEntityGroups]);
    useEffect(() => {
        const campaignLevelpermissions = permissionStructureData.filter((permission) => permission.level === 'Campaign');
        setMembers(
            campaignLevelpermissions
                .filter((permission) => permission.campaignMember !== undefined)
                .map((permission) => permission.campaignMember)
        );
        setReadOnlyMembers(campaignLevelpermissions.filter((permission) => permission.campaignReadOnlyMember !== undefined).map((permission) => permission.campaignReadOnlyMember, []));
    }, [permissionStructureData]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Column>
            <SectionWrapper>
                <Column style={{ alignItems: 'flex-start', marginBottom: 32 }}>
                    <Typography variant='h1' style={{
                        color: '#525252',
                        letterSpacing: '0.5px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        margin: '0 0 8px 0',
                        display: 'block',
                        float: 'none',
                        width: 'unset',
                        padding: 0
                    }}>Entity structure</Typography>
                    <Text color="#525252">
                        Select an existing entity structure from the templates
                        drop down
                    </Text>
                    <Text color="#525252">
                        menu or create a new one by adding a parent.
                    </Text>
                </Column>
                <Column style={{ marginBottom: 40 }}>
                    <AlignItems style={{ justifyContent: 'space-between' }}>
                        <Flex>
                            <CustomSelect
                                native
                                disabled
                                variant="outlined"
                                value={entityStatus}
                                onChange={handleSelectCampaignStatus}
                                IconComponent={() => null}
                                inputProps={{
                                    'aria-label': 'filter by entity status'
                                }}>
                                {entityStatusList.map(
                                    (campaignStatus: any, i: number) => (
                                        <option
                                            key={i}
                                            value={campaignStatus.id}
                                        >
                                            {campaignStatus.label}
                                        </option>
                                    )
                                )}
                            </CustomSelect>
                            <RedButton
                                disabled={
                                    !(
                                        clonedEntityGroups &&
                                        clonedEntityGroups.length
                                    )
                                }
                                style={{
                                    opacity:
                                        clonedEntityGroups &&
                                        clonedEntityGroups.length
                                            ? 1
                                            : 0.5
                                }}
                                onClick={handleClick}>
                                <AlignItems>
                                    <img src={templatesIcon} alt="template icon" aria-hidden={true} />
                                    <TemplatesButtonText>
                                        Templates
                                    </TemplatesButtonText>
                                    <img src={dropdownArrow} alt="dropdown icon" aria-hidden={true} />
                                </AlignItems>
                            </RedButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}>
                                <Column
                                    style={{
                                        overflowY: 'visible',
                                        maxHeight: '40vh',
                                        width: '20vw'
                                    }}>
                                    {clonedEntityGroups &&
                                        clonedEntityGroups.map(
                                            (
                                                entityGroup: any,
                                                entityGroupKey: number
                                            ) => (
                                                <CustomExpansionPanel
                                                    disabled={secondStep.some(
                                                        (parent) => entityGroup && (parent.name === entityGroup.name)
                                                    )}
                                                    key={entityGroupKey}>
                                                    <CustomExpansionPanelSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        disabled={
                                                            entityGroup.archivedDate !==
                                                            null
                                                        }
                                                    >
                                                        <SpaceBetween
                                                            style={{ flex: 1 }}
                                                        >
                                                            <Text style={{alignSelf: 'center'}}>
                                                                {
                                                                    entityGroup.name
                                                                }
                                                            </Text>
                                                            <Button
                                                                onClick={(
                                                                    e: React.MouseEvent<
                                                                        HTMLSpanElement,
                                                                        MouseEvent
                                                                    >
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    setClonedEntityGroups(
                                                                        (
                                                                            prevTemplates: CampaignEntityGroup[]
                                                                        ) => {
                                                                            const newTemplates = prevTemplates.filter(
                                                                                    (
                                                                                        _,
                                                                                        i: number
                                                                                    ) =>
                                                                                        i !==
                                                                                        entityGroupKey
                                                                                );
                                                                            !newTemplates.length &&
                                                                                setAnchorEl(
                                                                                    null
                                                                                );
                                                                            return newTemplates;
                                                                        }
                                                                    );
                                                                    setSelectedEntityGroups(
                                                                        selectedEntityGroups.concat(
                                                                            [
                                                                                {
                                                                                    editKey:
                                                                                        uuid(),
                                                                                    ...entityGroup,
                                                                                    campaignEntities:
                                                                                        entityGroup.campaignEntities
                                                                                            .filter(
                                                                                                (
                                                                                                    entity: any
                                                                                                ) =>
                                                                                                    entity.archivedDate ===
                                                                                                    null
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    entity: any
                                                                                                ) => ({
                                                                                                    editKey:
                                                                                                        uuid(),
                                                                                                    ...entity,
                                                                                                })
                                                                                            ),
                                                                                },
                                                                            ]
                                                                        )
                                                                    );
                                                                }}
                                                                style={{
                                                                    border: 'none',
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                            >
                                                                <Text>Select</Text>
                                                            </Button>
                                                        </SpaceBetween>
                                                    </CustomExpansionPanelSummary>
                                                    <Column
                                                        style={{
                                                            margin: '-24px 0 0 24px',
                                                            borderLeft:
                                                                '1px solid #d9d9d9',
                                                        }}
                                                    >
                                                        {entityGroup &&
                                                            entityGroup.campaignEntities &&
                                                            entityGroup.campaignEntities.map(
                                                                (
                                                                    entity: any,
                                                                    entityKey: number
                                                                ) => (
                                                                    <CustomExpansionPanelDetails
                                                                        key={
                                                                            entityKey
                                                                        }
                                                                    >
                                                                        {entity.archivedDate !==
                                                                        null ? (
                                                                            <DropdownEntity
                                                                                style={{
                                                                                    opacity: 0.5,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    entity.name
                                                                                }
                                                                            </DropdownEntity>
                                                                        ) : (
                                                                            <DropdownEntity>
                                                                                {
                                                                                    entity.name
                                                                                }
                                                                            </DropdownEntity>
                                                                        )}
                                                                    </CustomExpansionPanelDetails>
                                                                )
                                                            )}
                                                    </Column>
                                                </CustomExpansionPanel>
                                            )
                                        )}
                                </Column>
                            </Popover>
                            <RedButton
                                style={{ marginLeft: 16, paddingLeft: 16 }}
                                onClick={() => {
                                    setSelectedEntityGroup(null);
                                    setEntityGroupOpen(true);
                                }}
                            >
                                <AlignItems>
                                    <img src={iconGreyPlus} alt="plus" aria-hidden={true} />
                                    <TemplatesButtonText>
                                        Add parent
                                    </TemplatesButtonText>
                                </AlignItems>
                            </RedButton>
                        </Flex>
                    </AlignItems>
                </Column>
                <Tree
                    selectedEntityGroups={selectedEntityGroups}
                    searchString={searchString}
                    stakeholdersByClient={data && data.stakeholdersByClient}
                    setSelectedEntityGroups={setSelectedEntityGroups}
                    setEntityGroupOpen={setEntityGroupOpen}
                    setEntityOpen={setEntityOpen}
                    setSelectedEntityGroup={setSelectedEntityGroup}
                    setSelectedEntity={setSelectedEntity}
                    setClonedEntityGroups={setClonedEntityGroups}
                    campaignTitle={campaignTitle}
                    clientId={clientId}
                    members={members}
                    managerId={managerId}
                    readOnlyMembers={readOnlyMembers}
                    campaignId={campaignId}
                    permissionStructureData={permissionStructureData}
                    setPermissionStructureData={setPermissionStructureData}

                />
            </SectionWrapper>

            <EntityForm
                entityOpen={entityOpen}
                entityData={selectedEntityGroup}
                setSelectedEntityGroups={setSelectedEntityGroups}
                selectedEntityGroups={selectedEntityGroups}
                setSelectedEntityGroup={setSelectedEntityGroup}
                selectedRow={selectedEntity}
                clientId={clientId}
                setEntityOpen={setEntityOpen}
                setSelectedEntity={setSelectedEntity}
                newStakeHolders={newStakeHolders}
                setNewStakeholders={setNewStakeholders}
                allStakeholdersInCampaign={allStakeholdersInCampaign}
                setAllStakeholdersInCampaign={setAllStakeholdersInCampaign}
                campaignId={campaignId}
            />
            <EntityGroupForm
                dropdownValues={{
                    entityTypes: data && data.entityTypes,
                    stakeholdersByClient: data && data.stakeholdersByClient
                }}
                entityData={selectedEntityGroup}
                setSelectedEntityGroups={setSelectedEntityGroups}
                entityGroupOpen={entityGroupOpen}
                selectedEntityGroups={selectedEntityGroups}
                setSelectedEntityGroup={setSelectedEntityGroup}
                setEntityGroupOpen={setEntityGroupOpen}
                newStakeHolders={newStakeHolders}
                setNewStakeholders={setNewStakeholders}
                allStakeholdersInCampaign={allStakeholdersInCampaign}
                setAllStakeholdersInCampaign={setAllStakeholdersInCampaign}
            />
        </Column>
    );
};

const SectionWrapper = styled(Column)`
    padding: 20px;
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
`;

const CustomSelect = styled(Select)`
    padding-right: 48px;
    min-height: 48px;
    margin-right: 16px;
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 84px;
    }
`;

const DropdownEntity = styled(Text)`
    display: flex;
    // margin: 0 16px 16px 16px;
    &::before {
        content: '';
        border: 1px solid #d9d9d9;
        height: 0px;
        width: 10px;
        margin-top: 10px;
    }
`;

const CustomExpansionPanelDetails = styled(ExpansionPanelDetails)`
    && {
        padding: 32px 0 0 0;
        margin: 0;
    }
`;

const CustomExpansionPanelSummary = styled(ExpansionPanelSummary)`
    && {
        margin: 0 16px;
        padding: 0;
    }
`;

const CustomExpansionPanel = styled(ExpansionPanel)`
    && {
        padding: 8px 0;
    }
`;

const TemplatesButtonText = styled(Text)`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    margin: 0 16px 0 8px;
`;

export default SecondStep;
