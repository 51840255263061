import React, { useState, useEffect, useRef, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import logo from '../../../assets/images/pwc-logo.svg';
import notificationsIcon from '../../../assets/images/notificationsIcon.svg';
import settingsIcon from '../../../assets/images/icon-organisation-box.svg';
import dropdownArrow from '../../../assets/images/icon-drop-down.svg';
import analyseIcon from '../../../assets/images/icon-analyse.svg';
import benchmarkingIcon from '../../../assets/images/icon-benchmarking.svg';
import comparisonIcon from '../../../assets/images/icon-comparison.svg';
import threatAndRiskIcon from '../../../assets/images/icon-threat-risk-analysis.svg';
import headerDashboardIcon from '../../../assets/images/icon-dashboard.svg';
import headerEvaluateIcon from '../../../assets/images/icon-evaluate.svg';
import { setLocalStorageItem } from '../../../utils/local-storage';
import { capitalizeFirstLetter } from '../../../utils/UtilsHelpers';
import Router from '../../../routes/router';
import CustomMenu from '../../../components/Menu';
import { Client as ClientType } from '../../../context/reducer';
import {
    Flex,
    Text,
    BoldText,
    AlignItems,
    SpaceBetween,
    ArrowTooltip,
    Toast,
} from '../../../components';
import CompanyModal from './CompanyModal';
import { User, Client } from '../../../context/reducer';
import { HeaderButtonsTypes } from '../types';
import { removeJwtFromWhitelist } from '../../../services/ApolloClient/client';
import axios from 'axios';
import Badge from '@mui/material/Badge';
import Alert from '@mui/material/Alert';
import { TablePagination, Typography } from '@mui/material';
import { gql } from 'apollo-boost';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UserStore } from '../../../context/user-store';
import { isNotificationsEnabled, NOTIFICATION_FETCH_LIMIT } from '../../../utils';
import { useAuth } from 'react-oidc-context';

type UserDropDownProps = {
    user: User | null;
};

const NOTIFICATIONS = gql`
    query findNotificationByUserId($pagination: PaginationInput!) {
        findNotificationByUserId(pagination: $pagination)  {
            total
            skip
            take
            count
            items {
                id
                userId
                notificationType
                notificationCategory
                referenceID
                notificationMessage
                wasRead
                createdAt
            }

        }
    }
`;

const UPDATENOTIFICATIONS = gql`
    mutation updateNotification(
        $notificationID: String!
        $input: UpdateNotificationInput!
    ) {
        updateNotification(notificationID: $notificationID, input: $input) {
            wasRead
        }
    }
`;

const UserDropdown: React.FC<UserDropDownProps> = ({ user }) => {
    const [overflow, setOverflow] = useState(false);
    const myUserDropdown = useRef<any>(null);

    useEffect(() => {
        const myUserDropdownWidth = () => {
            if (myUserDropdown === null) {
                return;
            }
            myUserDropdown &&
            myUserDropdown.current &&
            myUserDropdown.current.offsetWidth <
                myUserDropdown.current.scrollWidth
                ? setOverflow(true)
                : setOverflow(false);
        };

        setTimeout(() => {
            myUserDropdownWidth();
        }, 500);
    }, []);

    return user ? (
        overflow ? (
            <ArrowTooltip
                top={-4}
                // interactive
                background="#2f2f2f"
                title={
                    <div
                        style={{
                            backgroundColor: '#2f2f2f',
                            textAlign: 'center',
                        }}
                    >
                        {user && user.firstName} {user && user.lastName}
                    </div>
                }
                placement="bottom"
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <WhiteText
                            ref={myUserDropdown}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {user && user.firstName} {user && user.lastName}
                        </WhiteText>
                        <img
                            style={{ width: '100%', maxWidth: '12px' }}
                            src={dropdownArrow}
                            alt="drop down arrow"
                            aria-hidden={true}
                        />
                    </div>
                    <GreyText
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '120px',
                        }}
                    >
                        {capitalizeFirstLetter(
                            user && user.jobTitle ? user.jobTitle : 'Admin'
                        )}
                    </GreyText>
                </div>
            </ArrowTooltip>
        ) : (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <WhiteText
                        ref={myUserDropdown}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {user && user.firstName} {user && user.lastName}
                    </WhiteText>
                    <img
                        style={{ width: '100%', maxWidth: '12px' }}
                        src={dropdownArrow}
                        alt="drop down arrow"
                        aria-hidden={true}
                    />
                </div>
                <GreyText
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '120px',
                    }}
                >
                    {capitalizeFirstLetter(
                        user && user.jobTitle ? user.jobTitle : ''
                    )}
                </GreyText>
            </div>
        )
    ) : null;
};

const AnalyseDropdown = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
            style={{ width: '24px' }}
            src={analyseIcon}
            alt="analyse icon"
            aria-hidden={true}
        />
        <DropdownTitle>Analyse</DropdownTitle>
        <img src={dropdownArrow} alt="drop down arrow" aria-hidden={true} />
    </div>
);

type Props = {
    user?: User;
    client?: Client;
    setRefreshState: any;
};

const Header: React.FC<Props> = ({ user, client, setRefreshState }) => {
    const auth = useAuth();
    const [companiesAreOpen, setCompaniesAreOpen] = useState(false);
    const [resetToast, setResetToast] = useState(false);
    const [resetToastIcon, setResetToastIcon] = useState<'error' | 'info' | 'check'>('check');
    const [resetToastMessage, setResetToastMessage] = useState('Your password reset was requested. Please check your email.');
    const BenchmarkingEnabled = client?.benchmarkingEnabled;
    useEffect(() => {
        setCompaniesAreOpen(
            !!(!client && user && user.clients && user.clients.length !== 1)
        );
    }, [user, client]);
    const [selected, setSelected] = useState(0);
    const route = Router.getCurrentPath();
    const [updateNotifications] = useMutation(UPDATENOTIFICATIONS);
    const [getNotifications, setGetNotifications] = useState<any>([]);
    const {
        state: { globalSettings },
    } = useContext(UserStore);

    const [ notificationPageOffset, setNotificationPagePageOffset] = useState<number>(0);

    const [notificationPageInfo, setNotificationPageInfo] = useState<{
        count: number;
        skip: number;
        take: number;
        total: number;
    }>({
        count: 0,
        skip: 0,
        take: 0,
        total: 0,
    });
    const [fetchNotification] = useLazyQuery<any>(NOTIFICATIONS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            setNotificationPageInfo({
                count: res.findNotificationByUserId.count,
                skip:  res.findNotificationByUserId.skip,
                take:  res.findNotificationByUserId.take,
                total: res.findNotificationByUserId.total,
            });
            setGetNotifications(res.findNotificationByUserId.items);

        },
        onError: (err) => console.error(err),
    });
    useEffect(() => {
        fetchNotification({
                variables: {
                    pagination: {
                        skip: notificationPageOffset * NOTIFICATION_FETCH_LIMIT,
                        take: NOTIFICATION_FETCH_LIMIT,
                    },
                },
            });

    }, [notificationPageOffset]);

    useEffect(() => {
        if (route.includes('dashboard')) {
            setSelected(0);
        } else if (route.includes('evaluate')) {
            setSelected(1);
        } else if (route.includes('analyse')) {
            setSelected(2);
        } else {
            setSelected(3);
        }
        setLocalStorageItem('isLoggedIn', 'isLoggedIn');
    }, [route]);

    const headerButtons = [
        {
            callback: Router.goToDashboard,
            imgSrc: headerDashboardIcon,
            imgAlt: 'dashboard icon',
            title: 'Dashboard',
        },
        {
            callback: Router.goToEvaluateCampaign,
            imgSrc: headerEvaluateIcon,
            imgAlt: 'Header evaluate icon',
            title: 'Evaluate',
        },
        {
            title: (
                <CustomMenu button={<AnalyseDropdown />} top={'-3%'}>
                    <MenuItem
                        disabled={!BenchmarkingEnabled}
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => Router.goToBenchmarking('', '')}
                    >
                        <ListItemIcon>
                            <img
                                src={benchmarkingIcon}
                                alt="benchmarking icon"
                                aria-hidden={true}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Heading>Benchmarking</Heading>}
                            secondary={
                                <SubHeading>
                                    Benchmark your client according to location
                                    and industry
                                </SubHeading>
                            }
                        />
                    </MenuItem>
                    {!BenchmarkingEnabled ? (
                        <MenuItem disabled style={{ opacity: 1 }}>
                            <BenchmarkingText>
                                Benchmarking is not enabled for this company
                            </BenchmarkingText>
                        </MenuItem>
                    ) : (
                        ''
                    )}
                    <MenuItem
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => Router.goToComparison('', '')}
                    >
                        <ListItemIcon>
                            <img
                                src={comparisonIcon}
                                alt="comparison icon"
                                aria-hidden={true}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Heading>Comparison</Heading>}
                            secondary={
                                <SubHeading>
                                    Compare year-on-year assessments of the same
                                    framework
                                </SubHeading>
                            }
                        />
                    </MenuItem>
                    <div
                        style={{
                            backgroundColor: '#ebebeb',
                            padding: '7px 0 7px 24px',
                        }}
                    >
                        <DividerTitle>Cyber Security</DividerTitle>
                    </div>
                    <MenuItem
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => Router.goToThreatAndRisk()}
                    >
                        <ListItemIcon>
                            <img
                                src={threatAndRiskIcon}
                                alt="threat and risk icon"
                                aria-hidden={true}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Heading>Threat and Risk Analysis</Heading>
                            }
                            secondary={
                                <SubHeading>
                                    Identify your clients performance against
                                    key threat scenarios and business risks
                                </SubHeading>
                            }
                        />
                    </MenuItem>
                </CustomMenu>
            ),
        },
    ];

    const { dispatch } = useContext(UserStore);

    const setCurrentClient = (currentClient: ClientType) => {
        dispatch({ type: 'SET_CLIENT', value: currentClient });
    };

    const handleOnClick = async (notificationId: string) => {
        const notification =  getNotifications.find((notify: any) => notify.id === notificationId);
        const data = JSON.parse(notification.referenceID);

        switch(notification.notificationCategory) {
            case 'COMPANY': {
                handleChangeCompany(notification.referenceID);
                await handleReadNotification(notification.id);
            }
            break;
            case 'CAMPAIGN': {
                try {
                    if(client?.id != data.companyId) {
                        handleChangeCompany(data.companyId);
                    }
                    await handleReadNotification(notification.id);
                    Router.goToMaturityOverview(data.campaignId);
                }
                catch (error) {
                    Router.goToMaturityOverview(notification.referenceID);
                }
            }
            break;
            case 'ASSESSMENT': {
                if(client?.id != data.companyId) {
                    handleChangeCompany(data.companyId);
                }
                await handleReadNotification(notification.id);
                if(data.capabilityId && data.domainId) {
                    Router.goToMaturityScore(data);
                }
            }
            break;
            default: {
                await handleReadNotification(notification.id);
            }
            break;
        }
    };

    const handleChangeCompany = (clientId: any) => {
        const chosenClient = (user && user.clients && user.clients.find(item => item.id === parseInt(clientId)));
        if (chosenClient) {
            setCurrentClient(chosenClient);
            setCompaniesAreOpen(!companiesAreOpen);
        }
    };

    const handleReadNotification = async (notificationId: string) => {
        await updateNotifications({
            variables: {
                notificationID: notificationId,
                input: { wasRead: true }
            },
        });
    };

    const CompanyDropdownTitle = () => {
        const [overflow, setOverflow] = useState(false);
        const myCompanyDropdown = useRef<any>(null);

        useEffect(() => {
            if (myCompanyDropdown.current === null) {
                return undefined;
            }
            const myCompanyDropdownWidth = () => {
                myCompanyDropdown &&
                myCompanyDropdown.current &&
                myCompanyDropdown.current.offsetWidth <
                    myCompanyDropdown.current.scrollWidth
                    ? setOverflow(true)
                    : setOverflow(false);
            };

            setTimeout(() => {
                myCompanyDropdownWidth();
            }, 500);
        }, []);
        return overflow ? (
            <ArrowTooltip
                top={-4}
                // interactive
                minWidth="280px"
                maxWidth="300px"
                background="#2f2f2f"
                title={
                    <div
                        style={{
                            backgroundColor: '#2f2f2f',
                            textAlign: 'center',
                        }}
                    >
                        {client && client.name}
                    </div>
                }
                placement="bottom"
            >
                {user && user.clients && user.clients.length > 1 ? (
                    <Flex
                        style={{
                            borderRight: '1px solid grey',
                            borderLeft: '1px solid grey',
                        }}
                    >
                        <ClientModalButton
                            onClick={() =>
                                setCompaniesAreOpen(!companiesAreOpen)
                            }
                        >
                            <WhiteText
                                ref={myCompanyDropdown}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {client && client.name}
                            </WhiteText>
                            <img
                                style={{ width: '100%', maxWidth: '12px' }}
                                src={dropdownArrow}
                                alt="drop down arrow"
                                aria-hidden={true}
                            />
                        </ClientModalButton>
                    </Flex>
                ) : (
                    <AlignItems>
                        <WhiteText
                            ref={myCompanyDropdown}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {client && client.name}
                        </WhiteText>
                    </AlignItems>
                )}
            </ArrowTooltip>
        ) : user && user.clients && user.clients.length > 1 ? (
            <Flex
                style={{
                    borderRight: '1px solid grey',
                    borderLeft: '1px solid grey',
                }}
            >
                <ClientModalButton
                    onClick={() => {
                        setCompaniesAreOpen(!companiesAreOpen);
                    }}
                >
                    <WhiteText
                        ref={myCompanyDropdown}
                        style={{
                            whiteSpace: 'wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {client && client.name}
                    </WhiteText>
                    <img
                        style={{ width: '100%', maxWidth: '12px' }}
                        src={dropdownArrow}
                        alt="drop down arrow"
                        aria-hidden={true}
                    />
                </ClientModalButton>
            </Flex>
        ) : (
            <AlignItems>
                <WhiteText
                    ref={myCompanyDropdown}
                    style={{
                        whiteSpace: 'wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {client && client.name}
                </WhiteText>
            </AlignItems>
        );
    };

    const onClickTemplate = (i: number, button: HeaderButtonsTypes) => {
        setRefreshState(Math.random());
        setSelected(i);
        button.callback && button.callback();
    };

    const resetPassword = () => {
        const tkn = localStorage.getItem('pid-token');
        const clientLocal = localStorage.getItem('client');
        let clientUUID = null;
        if (clientLocal) {
            const clientParsed = JSON.parse(clientLocal);
            clientUUID = clientParsed.id;
        }

        axios
            .post(
                window.__ENV__.REACT_APP_APP_URL + '/admin/reset-password',
                {},
                {
                    headers: {
                        'pearl-client-uuid': clientUUID,
                        Authorization: `Bearer ${tkn}`,
                    },
                }
            )
            .then((response) => {
                console.log(response);
                if (
                    response.status === 200 &&
                    response.data.ReturnCode === 'Success'
                ) {
                    setResetToast(true);
                    setResetToastIcon('check');
                    setResetToastMessage('Your password reset was requested. Please check your email.');
                } else if (
                    response.status === 200 &&
                    response.data.ReturnCode === 'Fail'
                ) {
                    setResetToast(true);
                    setResetToastIcon('error');
                    setResetToastMessage(`${response.data.ReturnCodeDescription} Please try again after 24 Hours.`);
                } else {
                    setResetToast(true);
                    setResetToastIcon('error');
                    setResetToastMessage('An unexpected error occurred while processing your request. Please try again later.');
                }
            })
            .catch((error) => {
                setResetToast(true);
                setResetToastIcon('error');
                setResetToastMessage('An unexpected error occurred while processing your request. Please try again later.');
                console.error(error);
            });
    };

    return (
        <HeaderRoot>
            <CustomAppBar position="static">
            <HeaderContent  href="#skip-to-content">Skip to content</HeaderContent>
                <HeaderWrapper>
                    <Flex>
                        <MenuButton
                            edge="start"
                            color="inherit"
                            aria-label="dashboard"
                            onClick={Router.goToSplash}
                        >
                            <img
                                style={{ width: '100%', minWidth: '200px' }}
                                src={logo}
                                alt="pwc logo"
                            />
                        </MenuButton>

                        <CompanyDropdownTitle />

                        <CompanyModal
                            companiesAreOpen={companiesAreOpen}
                            setCompaniesAreOpen={setCompaniesAreOpen}
                        />
                    </Flex>
                    <TabsContainer>
                        {headerButtons.map(
                            (button: HeaderButtonsTypes, i: number) => {
                                return typeof button.title !== 'string' ? (
                                    <SpaceBetweenWrapper
                                        key={i}
                                        onClick={() =>
                                            onClickTemplate(i, button)
                                        }
                                        style={{
                                            opacity: selected === i ? 1 : 0.6,
                                            minHeight: 53,
                                        }}
                                    >
                                        <HeaderButton>
                                            {button.title}
                                        </HeaderButton>
                                    </SpaceBetweenWrapper>
                                ) : (
                                    <Button
                                        key={i}
                                        onClick={() =>
                                            onClickTemplate(i, button)
                                        }
                                        style={{
                                            opacity: selected === i ? 1 : 0.6,
                                            minHeight: 53,
                                            padding: '6px 16px',
                                        }}
                                    >
                                        <img
                                            style={{ width: '24px' }}
                                            src={button.imgSrc}
                                            alt={button.imgAlt}
                                            aria-hidden={true}
                                        />
                                        <HeaderButton>
                                            {button.title}
                                        </HeaderButton>
                                    </Button>
                                );
                            }
                        )}
                    </TabsContainer>
                    <SpaceBetweenWrapper>
                        {isNotificationsEnabled(globalSettings) && (
                            <Badge
                                badgeContent={
                                    notificationPageInfo !== undefined
                                        ? notificationPageInfo.total
                                        : 0
                                }
                                color="error"
                                style={{ marginRight: 20 }}
                            >
                                <div style={{overflow: 'auto', position: 'relative'}}>
                                <CustomMenu
                                    button={
                                        <img
                                            src={notificationsIcon}
                                            alt="actions icon"
                                            aria-hidden={true}
                                        />
                                    }
                                    ariaLabel={'actions'}
                                    paperStyles={{ height: '430px' }}
                                >
                                    <div  style={{ position: 'sticky',top: 0,background: 'white', zIndex: 1}}>
                                    <TablePagination
                                    component="div"
                                    count={notificationPageInfo.total}
                                    rowsPerPage={notificationPageInfo.take}
                                    rowsPerPageOptions={[notificationPageInfo.take]}
                                    page={notificationPageOffset}
                                    onPageChange={(e: any, page: number) => {
                                        setNotificationPagePageOffset(page);
                                    }}
                                    onClick={(event: any)=> event.stopPropagation()}
                                     />
                                    </div>
                                    {getNotifications !== undefined && getNotifications.length > 0 ? (getNotifications.map(
                                            (notification: any, i: any) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <MenuItem key={`notification-${i}`}>
                                                    <Alert
                                                        style={{
                                                            width: '350px',
                                                        }}
                                                        onClick={()=> handleOnClick(notification.id)}
                                                        severity={notification.notificationType.toLowerCase()}
                                                        onClose={() => handleReadNotification(notification.id)}
                                                        >
                                                        <Typography
                                                            style={{
                                                                width: '100%',
                                                                whiteSpace:'pre-wrap',
                                                            }}
                                                        > { notification.notificationMessage }
                                                        </Typography>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                display: 'block',
                                                                textAlign: 'right',
                                                                width: '100%',
                                                                marginTop: '5px',
                                                            }}
                                                        > {new Date(notification.createdAt).toLocaleString('en-GB',
                                                                {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    second: '2-digit',
                                                                }
                                                            )}
                                                        </Typography>
                                                    </Alert>
                                                </MenuItem>
                                            )
                                        )
                                    ) : (
                                        <MenuItem>
                                            <Typography>
                                                No notifications
                                            </Typography>
                                        </MenuItem>
                                    )}
                                </CustomMenu>
                                </div>
                            </Badge>
                        )}
                        <CustomMenu
                            button={
                                <img
                                    src={settingsIcon}
                                    alt="actions icon"
                                    aria-hidden={true}
                                />
                            }
                            ariaLabel={'actions'}
                        >
                            {user && user.role === 'ADMIN' && (
                                <MenuItem disabled style={MenuDividerStyle}>
                                    <DividerTitle>
                                        Connected Risk Engine actions
                                    </DividerTitle>
                                </MenuItem>
                            )}
                            {user && user.role === 'ADMIN' && (
                                <MenuItem onClick={Router.goToAdmin}>
                                    <ListItemText primary="Admin portal" />
                                </MenuItem>
                            )}
                            <MenuItem disabled style={MenuDividerStyle}>
                                <DividerTitle>
                                    Organisational actions
                                </DividerTitle>
                            </MenuItem>
                            {user &&
                                user.role &&
                                user.countryUsers &&
                                user.countryUsers.some(
                                    (cu) => cu.campaignCreateEnabled
                                ) && (
                                    <MenuItem
                                        onClick={() =>
                                            Router.goToEvaluateCreateCampaign()
                                        }
                                    >
                                        <ListItemText primary="Create campaign" />
                                    </MenuItem>
                                )}
                            <MenuItem
                                onClick={Router.goToStakeholderManagement}
                            >
                                <ListItemText primary="Manage stakeholders" />
                            </MenuItem>
                            <MenuItem onClick={Router.goToCompanyStructure}>
                                <ListItemText primary="Company structure" />
                            </MenuItem>
                        </CustomMenu>
                        <CustomAvatar style={{ fontSize: '1rem' }}>
                            {user && user.firstName && user.firstName[0]}
                            {user && user.lastName && user.lastName[0]}
                        </CustomAvatar>
                        <CustomMenu
                            button={<UserDropdown user={user || null} />}
                        >
                            {user?.role?.toLowerCase() === 'external' && (
                                <MenuItem onClick={() => resetPassword()}>
                                    <ListItemText primary="Reset Password" />
                                </MenuItem>
                            )}
                            <MenuItem
                                onClick={async () => {
                                    auth.signoutSilent();
                                    auth.removeUser();
                                    await removeJwtFromWhitelist();
                                    Router.goToLogoutPage();
                                }}
                            >
                                <ListItemText primary="Logout" />
                            </MenuItem>
                        </CustomMenu>
                    </SpaceBetweenWrapper>
                </HeaderWrapper>
            </CustomAppBar>
            <Toast
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={resetToast}
                    autoHideDuration={9000}
                    icon={resetToastIcon}
                    width="450px"
                    title={resetToastMessage}
                    closeToast={() => setResetToast(false)}
                />
        </HeaderRoot>
    );
};

const HeaderRoot = styled('div')`
    flex-grow: 1;
`;

const MenuButton = styled(IconButton)`
    margin-right: 2px,
    flex: 1;
`;

const HeaderContent = styled('a')`
            left: -750px;
            margin: auto;
            padding:1px;
            position: absolute;
            overflow:hidden;
            z-index:-999;
         &:focus, &:active{
            color: #fff;
            text-decoration: none;
            background-color:#000;
            left: 750px;
            margin: auto;
            padding:1px;
            position: absolute;
            marginLeft: 1000px;
            border:1px solid white;
            text-align:center;
            font-size:15px;
            z-index:999;
        }
`;


const BenchmarkingText = styled(BoldText)`
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
`;
const SubHeading = styled(Text)`
    color: #6b6b6b;
    font-weight: 14px;
`;

const Heading = styled(BoldText)`
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const MenuDividerStyle = {
    backgroundColor: '#ebebeb',
    paddingTop: '7px',
    paddingBottom: '7px',
    opacity: '1',
};

const DividerTitle = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    color: #575757;
`;

const DropdownTitle = styled(Text)`
    font-size: 19px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 8px;
    text-transform: none;
`;

const HeaderButton = styled(DropdownTitle)`
    margin-left: 8px;
`;

const SpaceBetweenWrapper = styled(SpaceBetween)`
    align-items: center;
`;

const TabsContainer = styled(SpaceBetween)`
    align-items: center;
    margin-right: 7%;
`;

const CustomAppBar = styled(AppBar)`
    && {
        background-color: #2d2d2d;
        display: flex;
    }
`;

const CustomAvatar = styled(Avatar)`
    && {
        background-color: #de3328;
        color: #fff;
        margin-right: 16px;
        margin-left: 40px;
        font-weight: bold;
    }
`;

const HeaderWrapper = styled(SpaceBetween)`
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 72px;
`;

const ClientModalButton = styled(Button)`
    && {
        text-transform: none;
        min-height: 72px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const WhiteText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-right: 8px;
`;

const GreyText = styled(Text)`
    text-align: left;
    font-size: 13px;
    color: #cadeff;
`;

export default Header;
